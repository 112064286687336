/* eslint-disable no-console */

/* eslint-disable indent */
import { QuestionApi } from '../api/question'
import LoadingSpinner from '../components/LoadingSpinner'
import MVPCardGrid from '../components/MVPCard/MVPCardGrid'
import QrCodeModal from '../components/QrCodeModal'
import QuestionAssets from '../components/QuestionAssets'
import RespondingNodeioCard from '../components/RespondingNodeioCard'
import TextWithLinks from '../components/TextWithLinks'
import ThreeDotSpinner from '../components/ThreeDotSpinner'
import { useUserStore } from '../context/user/store'
import { Page } from '../enums/Page'
import { BarChartSummary } from '../types/BarChart'
import { MVPResponse } from '../types/MVPResponse'
import { Nodeio } from '../types/Nodeio'
import { Question, QuestionInsights } from '../types/Question'
import { QuestionNodeiosResponse } from '../types/QuestionNodeiosResponse'
import { QuestionTypeSettings } from '../types/QuestionType'
import { timeTodayDateElse } from '../utils/dateTimeFormatter'
import { pluralizationUtil } from '../utils/pluralizationUtil'
import * as Sentry from '@sentry/react'
import { useDebounce } from '@uidotdev/usehooks'
import { saveAs } from 'file-saver'
import { Tooltip } from 'flowbite-react'
import { toPng } from 'html-to-image'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
//import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from 'recharts'
import * as Recharts from 'recharts'

const QuestionLandingPage = (): JSX.Element => {
    const { username, slug } = useParams<{ username: string; slug: string }>()
    const navigate = useNavigate()
    const { state } = useLocation()
    const campaignId = state?.campaignId
    const [questions, setQuestions] = useState<Question[]>([])
    const [questionIndex, setQuestionIndex] = useState<number>(state?.questionIndex ?? 0)
    const debouncedQuestionIndex = useDebounce(questionIndex, 300)
    const [insights, setInsights] = useState<QuestionInsights | null>(null)
    const [nodeiosResponse, setNodeiosResponse] = useState<QuestionNodeiosResponse | null>(null)
    const [isCopied, setIsCopied] = useState<boolean>(false)
    const [isInsightsCopied, setIsInsightsCopied] = useState<boolean>(false)
    const [isDownloadTopTopicsSentimentClicked, setIsDownloadTopTopicsSentimentClicked] =
        useState<boolean>(false)
    const [isTranscriptCopied, setTranscriptCopied] = useState<boolean>(false)
    const [showQrCodeModal, setShowQrCodeModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingInsights, setLoadingInsights] = useState<boolean>(false)
    const [showFullInsights, setShowFullInsights] = useState<boolean>(false)
    const bottomRef = useRef<HTMLDivElement | null>(null)
    const insightsRef = useRef<HTMLDivElement>(null)
    const transcriptsRef = useRef<HTMLDivElement>(null)
    const topTopicsSentimentRef = useRef<HTMLDivElement>(null)
    const [showFullResponses, setShowFullResponses] = useState<boolean>(false)
    const [numberNewResponses, setNumberNewResponses] = useState<number>(0)
    const [insightsErrorMessage, setInsightsErrorMessage] = useState<string | null>(null)
    const [mvpResponse, setMVPResponse] = useState<MVPResponse | null>(null)
    const [showMoreInsightsLink, setShowMoreInsightsLink] = useState<boolean>(true)
    const [barChartData, setBarChartData] = useState<BarChartSummary[]>([])

    Sentry.captureMessage('QuestionLandingPage rendered')
    Sentry.captureMessage(`userAgent: ${navigator.userAgent}`)
    Sentry.captureMessage(`vendor: ${navigator.vendor}`)
    Sentry.captureMessage(`window: ${window}`)

    const {
        state: { user },
        setLoading: setLayoutLoading,
    } = useUserStore()

    const fetchRespondingNodeios = async (deleteCache?: boolean) => {
        // implement throttling to prevent too many requests
        if (questions[debouncedQuestionIndex]) {
            try {
                setLoading(true)
                const response = await QuestionApi.getRespondingNodeiosByQuestionId(
                    questions[debouncedQuestionIndex].id,
                    deleteCache ? 1 : nodeiosResponse?.pagination?.next_page ?? 1,
                    20,
                )
                setNodeiosResponse({
                    ...response.data,
                    nodeios: deleteCache
                        ? [...response.data.nodeios]
                        : [...(nodeiosResponse?.nodeios ?? []), ...response.data.nodeios],
                })
            } finally {
                setLoading(false)
                setInsightsErrorMessage(null)
            }
        }
    }

    const checkForNewNodeios = () => {
        if (nodeiosResponse?.nodeios && nodeiosResponse?.pagination?.total_items) {
            if (insights?.number_of_included_responses) {
                setNumberNewResponses(
                    nodeiosResponse?.pagination?.total_items -
                        insights.number_of_included_responses,
                )
            } else {
                setNumberNewResponses(nodeiosResponse?.pagination?.total_items)
            }
        }
    }

    const intersectionObserverCallback: IntersectionObserverCallback = (entries) => {
        if (
            entries[0].isIntersecting &&
            nodeiosResponse &&
            !loading &&
            nodeiosResponse?.pagination?.next_page
        ) {
            void fetchRespondingNodeios(false)
        }
    }

    // Function to calculate the unaccounted value for each item
    const calculateUnaccounted = (item: any) => {
        const positive = item.positive || item.Positive || 0
        const neutral = item.neutral || item.Neutral || 0
        const negative = item.negative || item.Negative || 0
        return 100 - (positive + neutral + negative)
    }

    const getInsightsForQuestion = async (questionId: string) => {
        try {
            const insightsResponse = await QuestionApi.getInsightsByQuestionId(questionId)
            if (insightsResponse?.data?.topic_sentiments) {
                try {
                    const topicSentimentsJSON = JSON.parse(insightsResponse.data.topic_sentiments)
                    // Calculate and display the unaccounted values for each item
                    for (const key in topicSentimentsJSON) {
                        const unaccounted = calculateUnaccounted(topicSentimentsJSON[key])
                        topicSentimentsJSON[key].unaccounted = unaccounted
                        insightsResponse.data.topic_sentiments = JSON.stringify(topicSentimentsJSON)
                    }
                } catch {}
            }
            if (
                questions[debouncedQuestionIndex]?.question_settings.type ===
                    QuestionTypeSettings.SINGLE_SELECT ||
                questions[debouncedQuestionIndex]?.question_settings.type ===
                    QuestionTypeSettings.MULTI_SELECT
            ) {
                //format the data for the bar chart for single select and multi select questions
                //data should be in the format [{name: 'option name', value: 10, total: 20}]
                //summary data is a string in the format * Option 1: Sunday: 31.58% (6 out of 19 responses)\n* Option 2: Monday: 31.58% (6 out of 19 responses)\n* Option 3: Tuesday: 10.53% (2 out of 19 responses)\n* Option 4: Wednesday: 5.26% (1 out of 19 responses)\n* Option 5: Thursday: 0.00% (0 out of 19 responses)\n* Option 6: Friday: 10.53% (2 out of 19 responses)\n* Option 7: Saturday: 5.26% (1 out of 19 responses)
                const summary = insightsResponse?.data?.summary.trim()
                if (summary) {
                    //console.log('Summary: ', summary)
                    const summaryData = summary.split('\n').map((item) => {
                        //console.log('Item: ', item)
                        const option = item.split(':')
                        //console.log('Option: ', option)
                        const optionName = option[0]?.trim() || ''
                        //console.log('Option Name: ', optionName)
                        const optionPercentage = option[1]?.split('(')[0].trim() || '0'
                        //console.log('Option Percentage: ', optionPercentage)
                        const optionValue = option[1]?.split('(')[1].split(' ')[0] || '0'
                        //console.log('Option Value: ', optionValue)
                        const optionTotal = option[1]?.split('(')[1].split(' ')[3] || '0'
                        //console.log('Option Total: ', optionTotal)
                        return {
                            name: optionName,
                            percentage: parseFloat(optionPercentage),
                            responses: parseInt(optionValue),
                            total: parseInt(optionTotal),
                        }
                    })
                    // eslint-disable-next-line no-console
                    //console.log('Summary Data: ', summaryData)
                    setBarChartData(summaryData)
                } else {
                    const summaryData = questions[
                        debouncedQuestionIndex
                    ]?.question_settings?.options?.map((element) => {
                        const optionName = element
                        return {
                            name: optionName,
                            percentage: 0,
                            responses: 0,
                            total: 0,
                        }
                    })

                    setBarChartData(summaryData || [])
                }
            }
            setInsights(insightsResponse?.data)
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Error getting insights: ' + e)
        }
    }

    const fetchMVPs = async (questionId: string) => {
        try {
            const response = await QuestionApi.getMVPsByQuestionId(questionId)
            setMVPResponse(response.data)
        } finally {
        }
    }

    useEffect(() => {
        if (isCopied) {
            const timeout = setTimeout(() => {
                setIsCopied(false)
            }, 1000)
            return () => clearTimeout(timeout)
        }

        if (isInsightsCopied) {
            const timeout2 = setTimeout(() => {
                setIsInsightsCopied(false)
            }, 1000)
            return () => clearTimeout(timeout2)
        }

        if (isDownloadTopTopicsSentimentClicked) {
            const timeout2 = setTimeout(() => {
                setIsDownloadTopTopicsSentimentClicked(false)
            }, 1000)
            return () => clearTimeout(timeout2)
        }

        if (isTranscriptCopied) {
            const timeout3 = setTimeout(() => {
                setTranscriptCopied(false)
            }, 1000)
            return () => clearTimeout(timeout3)
        }
    }, [isCopied, isInsightsCopied, isDownloadTopTopicsSentimentClicked, isTranscriptCopied])

    useEffect(() => {
        const loadData = async (): Promise<void> => {
            try {
                setLoading(true)
                if (username && slug) {
                    const response = await QuestionApi.getQuestionsByIdOrSlugForLandingPage(slug)
                    setQuestions(response.data)
                    if (user.insights_enabled) {
                        getInsightsForQuestion(response.data[debouncedQuestionIndex]?.id)
                    }
                    fetchMVPs(response.data[debouncedQuestionIndex]?.id)
                }
            } finally {
                setLoading(false)
            }
        }
        loadData()
    }, [username, slug, debouncedQuestionIndex])

    useEffect(() => {
        if (questions[debouncedQuestionIndex] && !loading) {
            setShowFullResponses(false)
            setLoading(true)
            setNodeiosResponse({
                ...(nodeiosResponse as QuestionNodeiosResponse),
                nodeios: [],
            })
            void fetchRespondingNodeios(true)
            if (user.insights_enabled) {
                getInsightsForQuestion(questions[debouncedQuestionIndex].id)
            }

            if (
                questions[debouncedQuestionIndex]?.question_settings.type !==
                    QuestionTypeSettings.SINGLE_SELECT &&
                questions[debouncedQuestionIndex]?.question_settings.type !==
                    QuestionTypeSettings.MULTI_SELECT
            ) {
                setShowMoreInsightsLink(true)
            } else {
                setShowMoreInsightsLink(false)
            }
        }
    }, [debouncedQuestionIndex, questions])

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1, // Trigger when 10% of the bottom element is visible
        }

        const observer = new IntersectionObserver(intersectionObserverCallback, options)

        if (bottomRef.current) {
            observer.observe(bottomRef.current)
        }

        // Cleanup function
        return () => {
            if (bottomRef.current) {
                observer.unobserve(bottomRef.current)
            }
        }
    }, [bottomRef, questions, nodeiosResponse, debouncedQuestionIndex]) // Include questionIndex in dependency array

    useEffect(() => {
        navigate('.', {
            replace: true,
            state: {
                ...state,
                questionIndex: debouncedQuestionIndex,
                campaignId: campaignId,
            },
        })
    }, [debouncedQuestionIndex])

    useEffect(() => {
        checkForNewNodeios()
    }, [nodeiosResponse])

    const toggleShowFullInsights = () => setShowFullInsights(!showFullInsights)
    const toggleShowFullResponses = () => {
        setNodeiosResponse({
            ...(nodeiosResponse as QuestionNodeiosResponse),
            nodeios: (nodeiosResponse?.nodeios || ([] as Nodeio[])).reduce(
                (arr: Nodeio[], cur: Nodeio) => {
                    arr.push({ ...cur, isExpanded: !showFullResponses })
                    return arr
                },
                [] as Nodeio[],
            ),
        })
        setShowFullResponses(!showFullResponses)
    }

    const nextQuestion = () => {
        setInsights(null)
        setQuestionIndex((index) => Math.min(index + 1, questions.length - 1))
    }

    const prevQuestion = () => {
        setInsights(null)
        setQuestionIndex((index) => Math.max(0, index - 1))
    }

    const toggleNodeioCardExpand = (id: string) => {
        const nodeios: Nodeio[] = (nodeiosResponse?.nodeios || ([] as Nodeio[])).reduce(
            (arr: Nodeio[], cur: Nodeio) => {
                if (cur.id === id) arr.push({ ...cur, isExpanded: !cur.isExpanded })
                else arr.push(cur)
                return arr
            },
            [] as Nodeio[],
        )

        if (nodeios.every((nodeio) => nodeio.isExpanded)) setShowFullResponses(true)
        if (nodeios.every((nodeio) => !nodeio.isExpanded)) setShowFullResponses(false)

        setNodeiosResponse({
            ...(nodeiosResponse as QuestionNodeiosResponse),
            nodeios,
        })
    }

    async function refreshInsights(): Promise<void> {
        setInsightsErrorMessage(null)
        setLoadingInsights(true)
        try {
            //await QuestionApi.gernerateQuestionInsights(questions[debouncedQuestionIndex].id)
            if (
                insights &&
                insights.updated_at &&
                new Date(insights.updated_at).getTime() + 600000 > new Date().getTime()
            ) {
                setInsightsErrorMessage(
                    'Insights were recently generated.  Please wait until 10 minutes have passed before generating new insights.',
                )
                setTimeout(() => {
                    setInsightsErrorMessage(null)
                }, 10000)
                setLoadingInsights(false)
                return
            }

            if (
                numberNewResponses > 0 &&
                nodeiosResponse?.nodeios &&
                nodeiosResponse?.nodeios.length > 1
            ) {
                try {
                    const response = await QuestionApi.gernerateQuestionInsights(
                        questions[debouncedQuestionIndex].id,
                    )
                    if (response.data) {
                        setInsights(response.data)
                    }
                    setNumberNewResponses(0)
                } catch (e) {
                    setInsightsErrorMessage('Error generating insights. Please try again later.')
                    setTimeout(() => {
                        setInsightsErrorMessage(null)
                    }, 10000)
                }
            } else {
                setInsightsErrorMessage(
                    'Two or more new responses are required to generate insights.',
                )
                setTimeout(() => {
                    setInsightsErrorMessage(null)
                }, 10000)
            }
            await fetchRespondingNodeios(true) //check for new responses
        } finally {
            setLoadingInsights(false)
        }
    }

    const downloadAllData = async () => {
        setLayoutLoading(true)
        try {
            const res = await QuestionApi.downloadQuestion(slug as string)
            if (res.status === 200) {
                var blob = new Blob([res.data])
                saveAs(blob, `${slug}.xlsx`)
            }
            setLayoutLoading(false)
        } catch (e) {
            setLayoutLoading(false)
        }
    }

    const downloadQuestionData = async (questionId: string) => {
        setLayoutLoading(true)
        try {
            const res = await QuestionApi.downloadQuestionData(questionId)
            if (res.status === 200) {
                var blob = new Blob([res.data])
                saveAs(blob, `${questionId}.xlsx`)
            }
            setLayoutLoading(false)
        } catch (e) {
            setLayoutLoading(false)
        }
    }

    const downloadBulkAudio = async () => {
        setLayoutLoading(true)
        try {
            const res = await QuestionApi.downloadBulkAudio(slug as string)
            if (res.status === 200) {
                var blob = new Blob([res.data])
                saveAs(blob, `${slug}.zip`)
            }
            setLayoutLoading(false)
        } catch (e) {
            setLayoutLoading(false)
        }
    }

    const deleteLink = async () => {
        setLayoutLoading(true)
        try {
            await Promise.all(
                questions.map((question: Question) => QuestionApi.deleteById(question.id)),
            )
            setLayoutLoading(false)
            if (campaignId && username) {
                navigate(
                    generatePath(Page.CAMPAIGN_LANDING_PAGE, {
                        username: username,
                        id: campaignId,
                    }),
                )
            } else {
                navigate(Page.HOME)
            }
        } catch (e) {
            setLayoutLoading(false)
        }
    }

    const copyInsightsToClipboard = (): void => {
        if (insights) {
            const insightsText = `SUMMARY\n\n${insights.summary}\n\nTOPICS\n\n${insights.themes}\n\nSENTIMENT\n\n${insights.sentiment}\n\n`
            navigator.clipboard.writeText(insightsText)
        }
        setIsInsightsCopied(true)
    }

    const downloadTopTopicsSentimentChart = () => {
        if (topTopicsSentimentRef.current === null) {
            return
        }

        const filter = (node: HTMLElement) => {
            const exclusionClasses = ['remove-me', 'secret-div']
            return !exclusionClasses.some((classname) => node.classList?.contains(classname))
        }

        toPng(topTopicsSentimentRef.current, {
            cacheBust: true,
            filter: filter,
            backgroundColor: '#1F3956',
            style: {
                transform: 'scale(0.9)',
                transformOrigin: 'top left',
                paddingTop: '10px',
                paddingBottom: '20px',
                paddingLeft: '30px',
                paddingRight: '30px',
            },
        })
            .then((dataUrl) => {
                const link = document.createElement('a')
                link.download = `${questions[debouncedQuestionIndex]?.question.replaceAll(
                    ' ',
                    '_',
                )}-TopTopicsSentimentChart.png`
                link.href = dataUrl
                link.click()
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err)
            })
    }

    // eslint-disable-next-line no-unused-vars
    const tickFormatter = (value: string, index: number) => {
        const limit = 20 // put your maximum character
        if (value.length < limit) return value
        return `${value.substring(0, limit)}...`
    }

    const questionAssets = questions[debouncedQuestionIndex]?.assets ?? []

    const areInsightsAvailable =
        insights?.topic_sentiments &&
        // replace " with an empty string to handle the case where the string is "Not Available"
        insights?.topic_sentiments.toLowerCase().replace(/"/g, '') !== 'not available' &&
        insights?.topic_sentiments.toLowerCase() !== 'n/a' &&
        Object.keys(insights.topic_sentiments).length > 0

    return (
        <div className="flex-1 px-[20px] sm:px-[40px] lg:pr-[120px] pb-10">
            <div className="flex flex-col sm:flex-row items-center justify-between mb-5 sm:mb-0">
                <div className="flex flex-row justify-start sm:justify-between w-full">
                    <button
                        className="text-[#007AFF] font-lato font-bold text-base pr-3"
                        onClick={() => {
                            // eslint-disable-next-line no-console
                            if (campaignId && username) {
                                navigate(
                                    generatePath(Page.CAMPAIGN_LANDING_PAGE, {
                                        username: user.username,
                                        id: campaignId,
                                    }),
                                )
                            } else {
                                navigate(Page.HOME)
                            }
                        }}
                    >
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.93934 13.9393C8.35355 14.5251 8.35355 15.4749 8.93934 16.0607L18.4853 25.6066C19.0711 26.1924 20.0208 26.1924 20.6066 25.6066C21.1924 25.0208 21.1924 24.0711 20.6066 23.4853L12.1213 15L20.6066 6.51472C21.1924 5.92893 21.1924 4.97919 20.6066 4.3934C20.0208 3.80761 19.0711 3.80761 18.4853 4.3934L8.93934 13.9393ZM12 13.5H10V16.5H12V13.5Z"
                                fill="#8092A5"
                            />
                        </svg>
                    </button>
                    <h2 className="text-[25px] flex-1 font-bold text-[#8092A5] ellipsis-1 text-left pr-12">
                        {questions[debouncedQuestionIndex]?.is_multi_question_link
                            ? questions[debouncedQuestionIndex]?.question_settings.title ||
                              'Multi-Question Link'
                            : questions[debouncedQuestionIndex]?.question_settings.title ||
                              questions[debouncedQuestionIndex]?.question}
                    </h2>
                </div>
                <div className="flex items-center justify-end  w-full gap-4">
                    <button
                        onClick={downloadAllData}
                        title="Download report"
                        className="p-1 sm:p-2"
                    >
                        <img src="/asset/download-icon.svg" alt="download_button" />
                    </button>
                    <button onClick={deleteLink}>
                        <img
                            alt="delete"
                            src="/asset/trash_bin.svg"
                            className="w-[26px] h-[26px]"
                        />
                    </button>
                    <button
                        onClick={() => {
                            navigate(
                                Page.EDIT_QUESTIONS.replace(':username', username ?? '').replace(
                                    ':slug',
                                    slug ?? '',
                                ),
                                {
                                    state: {
                                        ...state,
                                        questionIndex: debouncedQuestionIndex,
                                        campaignId: campaignId,
                                    },
                                },
                            )
                        }}
                    >
                        <img alt="edit" src="/asset/pencil.svg" className="w-[26px] h-[26px]" />
                    </button>
                    <a target="_blank" href={`${window.location.origin}/${username}/${slug}`}>
                        <img alt="navigate" src="/asset/goto.svg" className="w-[26px] h-[26px]" />
                    </a>
                </div>
            </div>
            <div className="flex-1">
                <div
                    className="mt-[30px] px-6 py-3 flex flex-col sm:flex-row justify-between items-center rounded-2xl gap-1 sm:gap-7"
                    style={{
                        background:
                            'var(--bg-for-questions, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #FFF)',
                    }}
                >
                    <p className="text-[#007AFF] ellipsis flex-1 break-all">
                        {`${window.location.origin}/${user.username}/${questions[debouncedQuestionIndex]?.slug}`}
                    </p>
                    <div className="flex flex-row gap-2 self-end sm:self-start items-center">
                        <button
                            className="p-1"
                            title="Scan QR code"
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setShowQrCodeModal(true)
                            }}
                        >
                            <img src="/asset/qr_code_scaner.svg" alt="qr_code" />
                        </button>
                        {isCopied && (
                            <p className="text-base w-[42px] leading-[30px] duration-300 transition-colors text-[#007AFF]">
                                Copied!
                            </p>
                        )}
                        {!isCopied && (
                            <button
                                className="p-1 w-[42px] flex justify-end"
                                title="Copy"
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    navigator.clipboard.writeText(
                                        `${window.location.origin}/${user?.username}/${questions[debouncedQuestionIndex]?.slug}`,
                                    )
                                    setIsCopied(true)
                                }}
                            >
                                <img src="/asset/copy.svg" alt="copy" />
                            </button>
                        )}
                    </div>
                </div>
                <div className="flex items-center justify-between mt-7">
                    <button
                        className="text-[#007AFF] text-sm disabled:text-[#007AFF80]"
                        disabled={nodeiosResponse?.pagination?.total_items === 0}
                        onClick={() => downloadQuestionData(questions[debouncedQuestionIndex]?.id)}
                    >
                        Download Question Data
                    </button>
                    {questions.length > 1 && (
                        <div className="flex gap-3 items-center justify-end">
                            <button
                                onClick={prevQuestion}
                                disabled={questionIndex === 0 || loadingInsights}
                                className="disabled:opacity-50"
                            >
                                <img alt="left" src="/asset/right.svg" className="rotate-180"></img>
                            </button>
                            <p className="text-base font-bold text-[#8092A5]">
                                Question {questionIndex + 1} of {questions.length}
                            </p>
                            <button
                                onClick={nextQuestion}
                                disabled={questionIndex === questions.length - 1 || loadingInsights}
                                className="disabled:opacity-50"
                            >
                                <img alt="left" src="/asset/right.svg"></img>
                            </button>
                        </div>
                    )}
                </div>
                <div
                    className={`${
                        !questions[debouncedQuestionIndex]?.question_settings.is_hidden
                            ? 'pt-9'
                            : 'pt-3'
                    } pb-7 px-6 rounded-lg min-h-[150px] mt-${questions.length > 1 ? 5 : 10}`}
                    style={{
                        background:
                            'var(--card, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #0D0D0D)',
                        boxShadow: '0px 2px 10px 0px rgba(0, 31, 64, 0.10)',
                    }}
                >
                    {questions[debouncedQuestionIndex]?.question_settings.is_hidden && (
                        <div className="flex justify-end -mr-1.5">
                            <Tooltip
                                content={<p>Hidden from link. Edit link to update</p>}
                                placement="bottom"
                                theme={{
                                    base: 'absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-bold shadow-sm !bg-[#8092A5] !text-[#001F40]',
                                    arrow: {
                                        base: 'absolute z-10 h-2 w-2 rotate-45 !bg-[#8092A5]',
                                    },
                                }}
                            >
                                <img src="/asset/visibility_off.svg" />
                            </Tooltip>
                        </div>
                    )}
                    <TextWithLinks
                        text={questions[debouncedQuestionIndex]?.question || ''}
                        className="text-white text-2xl font-semibold font-lato whitespace-pre-wrap"
                        linkClassName="text-[#007AFF]"
                    />
                    {questions[debouncedQuestionIndex]?.help_text && (
                        <TextWithLinks
                            text={questions[debouncedQuestionIndex]?.help_text || ''}
                            className="mt-2 text-white text-base font-lato whitespace-pre-wrap"
                            linkClassName="text-[#007AFF]"
                        />
                    )}
                    <div>
                        {questionAssets && questionAssets?.length > 0 && (
                            <QuestionAssets
                                className="mt-4"
                                key={questionIndex}
                                assets={questionAssets}
                                currentImageIndexStyle="text-white"
                            />
                        )}
                    </div>
                </div>
                {!loading && user.insights_enabled && (
                    <>
                        {/* show "Get two or more responses..." message when there are no insights */}
                        {!insights && (
                            <div className="bg-[#6bb2ff33] rounded-lg pt-4 pb-8 px-5 xs:px-9 mt-4">
                                <div
                                    className="bg-[#D9D9D921] sm:w-[420px] text-base rounded-[2rem] xs:rounded-full p-4 xs:p-2 pl-6 pr-3 xs:pl-8 xs:pr-12 text-black mt-2"
                                    style={{
                                        fontFamily: 'Raleway',
                                    }}
                                >
                                    <p>Get two or more responses to generate aggregate insights</p>
                                </div>
                            </div>
                        )}
                        {/* show insights */}
                        {insights && (
                            <div>
                                <div>
                                    {!!insights && (
                                        <div className="flex items-center gap-4 mt-8 mb-8">
                                            {loadingInsights ? (
                                                <>
                                                    <LoadingSpinner />
                                                    <p className="text-black text-base italic">
                                                        Refreshing Insights...please wait.
                                                    </p>
                                                </>
                                            ) : (
                                                <div className="w-full">
                                                    {numberNewResponses > 0 ? (
                                                        <>
                                                            <div className="flex flex-row justify-between">
                                                                <p className="text-black text-base italic">
                                                                    {numberNewResponses}
                                                                    {' new '}
                                                                    {pluralizationUtil(
                                                                        numberNewResponses,
                                                                        'response',
                                                                        'responses',
                                                                    )}
                                                                </p>
                                                                <div className="flex justify-end">
                                                                    <button
                                                                        onClick={refreshInsights}
                                                                        disabled={
                                                                            loadingInsights ||
                                                                            numberNewResponses === 0
                                                                        }
                                                                        className="text-sm text-white bg-[#007AFF] w-24 rounded-2xl p-1 self-center"
                                                                    >
                                                                        Refresh
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <p className="text-black text-base">
                                                                Insights are updated every 8 hours
                                                                when there are new responses. Last
                                                                update was at{' '}
                                                                {timeTodayDateElse(
                                                                    insights?.updated_at,
                                                                )}{' '}
                                                                with{' '}
                                                                {insights?.number_of_included_responses ||
                                                                    0}{' '}
                                                                {pluralizationUtil(
                                                                    insights?.number_of_included_responses ??
                                                                        0,
                                                                    'response',
                                                                    'responses',
                                                                )}
                                                                .
                                                            </p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="flex flex-row justify-between">
                                                                <p className="text-black text-xl">
                                                                    No new responses
                                                                </p>
                                                                <div className="flex justify-end">
                                                                    <button
                                                                        onClick={refreshInsights}
                                                                        disabled={
                                                                            loadingInsights ||
                                                                            numberNewResponses === 0
                                                                        }
                                                                        className="text-sm text-white bg-[#007AFF35] w-24 rounded-2xl p-1 self-center"
                                                                    >
                                                                        Refresh
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <p className="text-black text-base">
                                                                Insights are updated every 8 hours
                                                                when there are new responses. Last
                                                                update was at{' '}
                                                                {timeTodayDateElse(
                                                                    insights?.updated_at,
                                                                )}{' '}
                                                                with{' '}
                                                                {insights?.number_of_included_responses ||
                                                                    0}{' '}
                                                                {pluralizationUtil(
                                                                    insights?.number_of_included_responses ??
                                                                        0,
                                                                    'response',
                                                                    'responses',
                                                                )}
                                                                .
                                                            </p>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="bg-[#6bb2ff33] rounded-lg pt-4 pb-8 px-5 xs:px-10 mt-4">
                                    <div className="flex items-center gap-4 justify-end">
                                        {!!insightsErrorMessage && (
                                            <p className="text-[#FF0000] text-base font-bold">
                                                {insightsErrorMessage}
                                            </p>
                                        )}
                                    </div>
                                    <div className="flex flex-col xl:flex-row gap-5 md:gap-10 mt-1">
                                        <div
                                            ref={insightsRef}
                                            className="flex flex-col flex-1 mt-1"
                                        >
                                            <div className="flex flex-row justify-between">
                                                <p
                                                    className={`font-lato text-base font-bold text-black mb-3`}
                                                >
                                                    Summary
                                                </p>
                                                {isInsightsCopied && (
                                                    <p className="text-base w-[42px] leading-[30px] duration-300 transition-colors text-[#007AFF]">
                                                        Copied!
                                                    </p>
                                                )}
                                                {!isInsightsCopied && (
                                                    <button
                                                        className="p-1 w-[32px] flex justify-end"
                                                        title="Copy Insights"
                                                        onClick={copyInsightsToClipboard}
                                                        disabled={isInsightsCopied}
                                                    >
                                                        <img src="/asset/copy.svg" alt="copy" />
                                                    </button>
                                                )}
                                            </div>
                                            {!insights ? (
                                                <div
                                                    className="bg-[#D9D9D921] sm:w-[420px] text-base rounded-[2rem] xs:rounded-full p-4 xs:p-2 pl-6 pr-3 xs:pl-8 xs:pr-12 text-black mt-2"
                                                    style={{
                                                        fontFamily: 'Raleway',
                                                    }}
                                                >
                                                    {nodeiosResponse &&
                                                    nodeiosResponse?.pagination?.total_items > 1
                                                        ? '⚙️ Our engine is working on your insights! Check back soon or click Refresh to refresh insights now.'
                                                        : '💡 Get two or more responses to generate aggregate insights.'}
                                                </div>
                                            ) : (
                                                <>
                                                    {(questions[debouncedQuestionIndex]
                                                        ?.question_settings.type ===
                                                        QuestionTypeSettings.SINGLE_SELECT ||
                                                        questions[debouncedQuestionIndex]
                                                            ?.question_settings.type ===
                                                            QuestionTypeSettings.MULTI_SELECT) && (
                                                        <div className="w-full h-full bg-white pt-2 pb-2">
                                                            <Recharts.ResponsiveContainer
                                                                width="99%"
                                                                aspect={3}
                                                            >
                                                                <Recharts.BarChart
                                                                    data={barChartData}
                                                                    margin={{
                                                                        top: 20,
                                                                        right: 10,
                                                                        left: 10,
                                                                        bottom: 60,
                                                                    }}
                                                                >
                                                                    <Recharts.CartesianGrid
                                                                        horizontal={false}
                                                                        vertical={false}
                                                                    />
                                                                    <Recharts.XAxis
                                                                        dataKey="name"
                                                                        interval={0}
                                                                        angle={-20}
                                                                        textAnchor="end"
                                                                        tickFormatter={
                                                                            tickFormatter
                                                                        }
                                                                    />
                                                                    <Recharts.YAxis />
                                                                    <Recharts.Tooltip />
                                                                    <Recharts.Legend
                                                                        verticalAlign="top"
                                                                        height={36}
                                                                    />
                                                                    <Recharts.Bar
                                                                        dataKey="responses"
                                                                        stackId="a"
                                                                        fill="#8884d8"
                                                                        maxBarSize={50}
                                                                        label={{ position: 'top' }}
                                                                    />
                                                                    {/* <Recharts.Bar
                                                                        dataKey="total"
                                                                        stackId="a"
                                                                        fill="#82ca9d"
                                                                    /> */}
                                                                </Recharts.BarChart>
                                                            </Recharts.ResponsiveContainer>
                                                            {/* <p className="content mt-2 mb-1 pb-2 text-black">
                                                                Total Responses:{' '}
                                                                {barChartData[0].total}
                                                            </p> */}
                                                        </div>
                                                    )}
                                                    {questions[debouncedQuestionIndex]
                                                        ?.question_settings.type !==
                                                        QuestionTypeSettings.SINGLE_SELECT &&
                                                        questions[debouncedQuestionIndex]
                                                            ?.question_settings.type !==
                                                            QuestionTypeSettings.MULTI_SELECT && (
                                                            <div>
                                                                {/* <p
                                                            className={`font-lato text-base font-bold text-black mb-3 mt-[34px]`}
                                                        >
                                                            Top Topics
                                                        </p>
                                                        <p
                                                            className={`whitespace-pre-line text-sm text-black ${
                                                                showFullInsights
                                                                    ? ''
                                                                    : 'ellipsis-3 pr-[100px]'
                                                            }`}
                                                        >
                                                            {insights?.themes}
                                                        </p> */}
                                                                <p
                                                                    className={`whitespace-pre-line text-sm text-black ${
                                                                        !showFullInsights &&
                                                                        showMoreInsightsLink &&
                                                                        'three-lines-clamp'
                                                                    }`}
                                                                >
                                                                    {insights?.summary}
                                                                </p>
                                                                <p
                                                                    className={`font-lato text-base font-bold text-black mb-3 mt-[34px] ${
                                                                        showFullInsights
                                                                            ? ''
                                                                            : 'hidden'
                                                                    }`}
                                                                >
                                                                    Sentiment
                                                                </p>
                                                                <p
                                                                    className={`text-sm text-black ${
                                                                        showFullInsights
                                                                            ? ''
                                                                            : 'hidden'
                                                                    }`}
                                                                >
                                                                    {insights?.sentiment}
                                                                </p>
                                                            </div>
                                                        )}

                                                    <div className="flex-1" />

                                                    {showMoreInsightsLink && (
                                                        <button
                                                            className="text-[#007AFF] text-base font-bold flex items-center flex-1 justify-end mt-3"
                                                            onClick={toggleShowFullInsights}
                                                        >
                                                            {showFullInsights
                                                                ? 'Read less'
                                                                : 'More insights'}
                                                        </button>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        {questions[debouncedQuestionIndex]?.question_settings
                                            .type !== QuestionTypeSettings.SINGLE_SELECT &&
                                            questions[debouncedQuestionIndex]?.question_settings
                                                .type !== QuestionTypeSettings.MULTI_SELECT && (
                                                <div className="w-full md:w-[2px] h-[2px] md:h-auto bg-[rgba(182,_214,_250,_0.35)]" />
                                            )}
                                        {questions[debouncedQuestionIndex]?.question_settings
                                            .type !== QuestionTypeSettings.SINGLE_SELECT &&
                                            questions[debouncedQuestionIndex]?.question_settings
                                                .type !== QuestionTypeSettings.MULTI_SELECT && (
                                                <div
                                                    ref={topTopicsSentimentRef}
                                                    className="flex-1 2xl:flex-[0.7] mt-1"
                                                >
                                                    <div className="flex flex-row justify-between">
                                                        <p className="text-black text-lg font-semibold mb-4">
                                                            Sentiment of Top Topics
                                                        </p>
                                                        <button
                                                            className="remove-me p-1 w-[32px] flex justify-end"
                                                            title="Copy Insights"
                                                            onClick={
                                                                downloadTopTopicsSentimentChart
                                                            }
                                                            disabled={
                                                                isDownloadTopTopicsSentimentClicked
                                                            }
                                                        >
                                                            <img src="/asset/copy.svg" alt="copy" />
                                                        </button>
                                                    </div>
                                                    {areInsightsAvailable &&
                                                        Object.keys(
                                                            JSON.parse(
                                                                insights.topic_sentiments || '{}',
                                                            ),
                                                        ).map((key: string) => {
                                                            let sentiment = JSON.parse(
                                                                insights.topic_sentiments || '{}',
                                                            )[key]
                                                            return (
                                                                <div key={key} className="my-4">
                                                                    <p className="text-sm text-black mb-2">
                                                                        {key}
                                                                    </p>
                                                                    {Object.keys(sentiment).filter(
                                                                        (k) => sentiment[k] > 0,
                                                                    ).length > 0 ? (
                                                                        <div className="flex items-center rounded-full ">
                                                                            {Object.keys(sentiment)
                                                                                .filter(
                                                                                    (k) =>
                                                                                        sentiment[
                                                                                            k
                                                                                        ] > 0,
                                                                                )
                                                                                .reverse()
                                                                                .map(
                                                                                    (
                                                                                        k,
                                                                                        index: number,
                                                                                        arr,
                                                                                    ) => (
                                                                                        <div
                                                                                            key={k.toLowerCase()}
                                                                                            className={`flex items-center justify-center ${
                                                                                                k.toLowerCase() ===
                                                                                                'negative'
                                                                                                    ? 'bg-[#F79009]'
                                                                                                    : k.toLowerCase() ===
                                                                                                      'neutral'
                                                                                                    ? 'bg-[#7839EE]'
                                                                                                    : k.toLowerCase() ===
                                                                                                      'positive'
                                                                                                    ? 'bg-[#47CD89]'
                                                                                                    : 'bg-[#a0aec0]'
                                                                                            } ${
                                                                                                index ===
                                                                                                    0 &&
                                                                                                index ===
                                                                                                    arr.length -
                                                                                                        1
                                                                                                    ? 'rounded-full'
                                                                                                    : index ===
                                                                                                      0
                                                                                                    ? 'rounded-l-full'
                                                                                                    : index ===
                                                                                                      arr.length -
                                                                                                          1
                                                                                                    ? 'rounded-r-full'
                                                                                                    : ''
                                                                                            }`}
                                                                                            style={{
                                                                                                width:
                                                                                                    sentiment[
                                                                                                        k
                                                                                                    ] +
                                                                                                    '%',
                                                                                            }}
                                                                                        >
                                                                                            <p className="text-xs text-black font-semibold p-1.5">
                                                                                                {
                                                                                                    sentiment[
                                                                                                        k
                                                                                                    ]
                                                                                                }
                                                                                                %
                                                                                            </p>
                                                                                        </div>
                                                                                    ),
                                                                                )}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="flex items-center justify-center bg-gray-500 rounded-full">
                                                                            <p className="text-xs text-black font-semibold p-1.5">
                                                                                Not Available
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )
                                                        })}
                                                    <div className="flex flex-col sm:flex-row md:flex-ro xl:flex-row items-end sm:items-center md:items-end xl:items-center justify-between mt-6 xl:mt-4 gap-3 xl:gap-5">
                                                        <div className="flex items-center gap-4">
                                                            <div className="flex items-center gap-1">
                                                                <div className="rounded-full w-[16px] h-[16px] bg-[#F79009]" />
                                                                <p className="text-xs text-black">
                                                                    Negative
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center gap-1">
                                                                <div className="rounded-full w-[16px] h-[16px] bg-[#7839EE]" />
                                                                <p className="text-xs text-black">
                                                                    Neutral
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center gap-1">
                                                                <div className="rounded-full w-[16px] h-[16px] bg-[#47CD89]" />
                                                                <p className="text-xs text-black">
                                                                    Positive
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center gap-1">
                                                                <div className="rounded-full w-[16px] h-[16px] bg-[#a0aec0]" />
                                                                <p className="text-xs text-black">
                                                                    Not Available
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="text-sm text-black">
                                                            *% sentiment on topic.
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}

                {questions[debouncedQuestionIndex]?.question_settings.type !==
                    QuestionTypeSettings.SINGLE_SELECT &&
                    questions[debouncedQuestionIndex]?.question_settings.type !==
                        QuestionTypeSettings.MULTI_SELECT && (
                        <div className="bg-[#6bb2ff33] rounded-lg pt-4 pb-8 px-1 xs:px-5 lg:px-7 mt-10 flex flex-col">
                            <div className="flex items-center">
                                <p className="text-black text-lg py-2 px-2">Soundbites</p>

                                {/* <Tooltip
                                    content={`Nodeio's goal is to help brands quickly understand customers perspectives at scale. So we use AI to identify the most valuable responses (MVPs) and the most impactful statement in each response which is the 'impact statement' seen after the double quote on each card.`}
                                    className="text-base px-5 py-2 max-w-[300px]"
                                    placement="right"
                                    theme={{
                                        base: 'absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-bold shadow-sm !bg-[#8092A5] !text-[#001F40]',
                                        arrow: {
                                            base: 'absolute z-10 h-2 w-2 rotate-45 !bg-[#8092A5]',
                                        },
                                    }}
                                >
                                    <img src="/asset/help-circle.svg" />
                                </Tooltip> */}
                            </div>
                            {loadingInsights ? (
                                <div className="flex justify-center mt-4">
                                    <ThreeDotSpinner />
                                </div>
                            ) : !_.isEmpty(mvpResponse?.user_selected_mvp_nodeios) ||
                              !_.isEmpty(mvpResponse?.nodeios) ? (
                                <>
                                    <MVPCardGrid
                                        mvps={
                                            !_.isEmpty(mvpResponse?.user_selected_mvp_nodeios)
                                                ? (mvpResponse?.user_selected_mvp_nodeios.filter(
                                                      (mvp: Nodeio) =>
                                                          mvp.impact_statement?.search(
                                                              'Impact statement not available',
                                                          ) !== 0,
                                                  ) as Nodeio[])
                                                : (mvpResponse?.nodeios.filter(
                                                      (mvp: Nodeio) =>
                                                          mvp.impact_statement?.search(
                                                              'Impact statement not available',
                                                          ) !== 0,
                                                  ) as Nodeio[])
                                        }
                                        type="question"
                                    />
                                    <button
                                        className="text-sm text-white bg-[#007AFF] rounded-2xl p-2 px-6 self-center mt-4"
                                        onClick={() =>
                                            navigate(
                                                generatePath(Page.QUESTION_LANDING_PAGE_MORE, {
                                                    username: username as string,
                                                    slug: slug as string,
                                                    id: questions[debouncedQuestionIndex].id,
                                                }),
                                                {
                                                    state: {
                                                        questionIndex: questionIndex,
                                                        campaignId: campaignId,
                                                    },
                                                },
                                            )
                                        }
                                    >
                                        View all
                                    </button>
                                </>
                            ) : (
                                <div>
                                    <p className="ml-2 mt-2 text-left text-base text-black">
                                        {nodeiosResponse?.nodeios &&
                                        nodeiosResponse.nodeios.length > 0
                                            ? 'Hmm, looks like we couldn’t find any. We’ll try again on the next refresh.'
                                            : 'No voices yet.'}
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
            </div>

            <div className="flex flex-col mt-8">
                <div className="flex items-center">
                    <p
                        className="text-[#8092A5] text-base font-bold mb-2 flex-1"
                        style={{
                            fontFamily: 'Raleway',
                        }}
                    >
                        {!nodeiosResponse || nodeiosResponse?.pagination?.total_items === 0
                            ? 0
                            : nodeiosResponse?.pagination?.total_items}{' '}
                        {pluralizationUtil(
                            nodeiosResponse?.pagination?.total_items as number,
                            'Respondent',
                            'Respondents',
                        )}
                    </p>
                    <button
                        className="text-[#007AFF] text-sm mr-4 disabled:text-[#007AFF80] mt-14 xs:mt-0"
                        disabled={nodeiosResponse?.pagination?.total_items === 0}
                        onClick={toggleShowFullResponses}
                    >
                        {showFullResponses ? 'Minimize' : 'Expand'}
                    </button>
                    {questions[debouncedQuestionIndex]?.question_settings.type ===
                        QuestionTypeSettings.VOICE_ONLY && (
                        <button
                            className="text-[#007AFF] text-sm disabled:text-[#007AFF80] mt-14 xs:mt-0"
                            disabled={nodeiosResponse?.pagination?.total_items === 0}
                            onClick={downloadBulkAudio}
                        >
                            Download Audio
                        </button>
                    )}
                    {/* {isTranscriptCopied ? (
                            <p className="text-sm leading-[30px] duration-300 transition-colors text-[#007AFF] w-[130px] text-end">
                                Copied!
                            </p>
                        ) : (
                            <button
                                className="text-[#007AFF] text-sm flex items-center disabled:text-[#007AFF80] w-[130px] text-end"
                                disabled={nodeiosResponse?.pagination?.total_items === 0}
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    navigator.clipboard.writeText(
                                        transcriptsRef.current?.innerText as string,
                                    )
                                    setTranscriptCopied(true)
                                }}
                            >
                                <svg
                                    className={`mr-1 opacity-${
                                        nodeiosResponse?.pagination?.total_items === 0 ? 50 : 100
                                    }`}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 29 27"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_4932_23111)">
                                        <path
                                            d="M18.8486 1.125H4.8623C3.58022 1.125 2.53125 2.1375 2.53125 3.375V19.125H4.8623V3.375H18.8486V1.125ZM22.3452 5.625H9.5244C8.24232 5.625 7.19335 6.6375 7.19335 7.875V23.625C7.19335 24.8625 8.24232 25.875 9.5244 25.875H22.3452C23.6273 25.875 24.6762 24.8625 24.6762 23.625V7.875C24.6762 6.6375 23.6273 5.625 22.3452 5.625ZM22.3452 23.625H9.5244V7.875H22.3452V23.625Z"
                                            fill="#007AFF"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4932_23111">
                                            <rect
                                                width="27.9726"
                                                height="27"
                                                fill="white"
                                                transform="translate(0.203125)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                                Copy transcripts
                            </button>
                        )} */}
                </div>
                {(!nodeiosResponse || nodeiosResponse?.pagination?.total_items === 0) && (
                    <p
                        className="text-[#8092A5] text-base mt-4 xs:mt-0"
                        style={{
                            fontFamily: 'Raleway',
                        }}
                    >
                        👀 Nothing yet...
                    </p>
                )}
                {nodeiosResponse && nodeiosResponse?.pagination?.total_items > 0 && (
                    <div ref={transcriptsRef} className="flex flex-col gap-4 mt-4 mb-12">
                        {nodeiosResponse.nodeios.map((nodeio, index) => (
                            <RespondingNodeioCard
                                key={index}
                                nodeio={nodeio}
                                question={questions[debouncedQuestionIndex]}
                                toggleExpand={toggleNodeioCardExpand}
                                isExpanded={nodeio.isExpanded}
                                showFullResponses={showFullResponses}
                            />
                        ))}
                        {loading && <p className="text-sm text-black">loading...</p>}
                    </div>
                )}
                <div ref={bottomRef} style={{ height: '1px' }}></div>
            </div>
            <div>
                {showQrCodeModal && questions[debouncedQuestionIndex] && (
                    <QrCodeModal
                        onClose={() => setShowQrCodeModal(false)}
                        question={questions[debouncedQuestionIndex]}
                    />
                )}
            </div>
        </div>
    )
}
export default QuestionLandingPage
