/* eslint-disable indent */
import { NodeioApi } from '../api/nodeio'
import { QuestionApi } from '../api/question'
import AddMoreInformation from '../components/AddMoreInformation'
import MultipleSelectResponder from '../components/MultipleSelectResponder'
import QuestionResponderQuestionCard from '../components/QuestionResponderQuestionCard'
import ResponsePreview from '../components/ResponsePreview'
import SelectLanguage from '../components/SelectLanguage'
import SingleSelectResponder from '../components/SingleSelectResponder'
import Spinner from '../components/Spinner'
import VoiceOnlyResponder from '../components/VoiceOnlyResponder'
import VoiceTextResponder from '../components/VoiceTextResponder'
import { useQuestionStore } from '../context/question/store'
import { useUserStore } from '../context/user/store'
import { Mandatory } from '../enums/Mandatory'
import { Page } from '../enums/Page'
import { Client } from '../types/CreateNodeioPayload'
import { Question } from '../types/Question'
import { QuestionTypeSettings } from '../types/QuestionType'
import { defaultBrandBgColor } from '../utils/constant'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { clarity } from 'clarity-js'
import _ from 'lodash'
// @ts-ignore
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 } from 'uuid'

export interface Response {
    questionId: string
    length?: number
    formData?: FormData
    title?: string
}

export interface ResponseNote {
    questionId: string
    note: string
    open: boolean
    selectedQuestionType?: QuestionTypeSettings
    title?: any
}

export enum Language {
    ENGLISH = 'en',
    SPANISH = 'es',
    FRENCH = 'fr',
    CHINESE = 'zh',
    JAPANESE = 'ja',
    KOREAN = 'ko',
    GERMAN = 'de',
    ITALIAN = 'it',
    HINDI = 'hi',
    HAITIAN_CREOLE = 'ht',
}

const QuestionResponder = (): JSX.Element => {
    const { idOrSlug } = useParams<{ idOrSlug: string }>()
    const {
        state: { loading },
        setLoading,
    } = useUserStore()

    const {
        state: { isQuestionResponderLanded, accepted, openFooter },
        setAccepted,
        setOpenFooter,
        setQuestionResponderLanded,
    } = useQuestionStore()

    const CLARITY_PROJECT_ID = 'j8qkp85nmj'
    const [questionIndex, setQuestionIndex] = useState<number>(0)
    const [questions, setQuestions] = useState<Question[]>([])
    const [responses, setResponses] = useState<Response[]>([])
    const [responseNotes, setResponseNotes] = useState<ResponseNote[]>([])
    const [requiredToAllResponds, setRequiredToAllResponds] = useState<boolean>(false)

    const [isRecordingSupported, setIsRecordingSupported] = useState<boolean>(true)
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [errorSubmitting, setErrorSubmitting] = useState<boolean>(false)
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(true)
    const [isRecording, setIsRecording] = useState<boolean>(false)
    const navigate = useNavigate()

    const FINAL_THIRD_FOUNDATION_ID = '3b19372a-3d69-4d49-bb75-b18d40f1e82b'

    const selectedQuestion: Question | null =
        questions && questions.length ? questions[questionIndex] ?? null : null
    const responseForSelectedQuestion: Response | undefined = responses.find(
        (response) => response.questionId === selectedQuestion?.id,
    )
    const responseNoteForSelectedQuestion: ResponseNote | undefined = responseNotes.find(
        (response) => response.questionId === selectedQuestion?.id,
    )
    const { i18n, t } = useTranslation()

    const requestId = v4()

    const nonRespondedRequiredQuestions: Question[] = questions.filter(
        (question) =>
            question.question_settings.require_question_response === Mandatory.REQUIRED &&
            !responses.find((response) => response.questionId === question.id) &&
            !responseNotes.find(
                (responseNote) =>
                    responseNote.questionId === question.id &&
                    !_.isEmpty(
                        Array.isArray(responseNote.title)
                            ? responseNote.title
                            : responseNote.title?.trim(),
                    ),
            ),
    )

    useEffect(() => {
        if (shouldBlockNavigation) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = null
        }
    }, [shouldBlockNavigation])

    const fetchQuestion = async () => {
        setLoading(true)
        if (idOrSlug) {
            try {
                const response = await QuestionApi.getQuestionsByIdOrSlug(idOrSlug, true)
                setQuestions(
                    response.data.filter(
                        (question) => question.question_settings?.is_hidden !== true,
                    ),
                )
                setResponseNotes(
                    response.data
                        .filter((question) => question.question_settings?.is_hidden !== true)
                        .map((question) => ({
                            questionId: question.id,
                            note: '',
                            open: false,
                            title:
                                question.question_settings.type ===
                                QuestionTypeSettings.MULTI_SELECT
                                    ? []
                                    : undefined,
                        })),
                )

                setQuestionIndex(0)
                setLoading(false)
            } catch (e: any) {
                navigate(Page.LINK_EXPIRED)
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        void fetchQuestion()
    }, [idOrSlug])

    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'production') {
            clarity.consent()
            clarity.start({
                projectId: CLARITY_PROJECT_ID,
                upload: 'https://m.clarity.ms/collect',
                track: true,
                content: true,
            })

            return () => {
                clarity.stop()
            }
        }
    }, [])

    const uploadFile = async (file: File, signedUrl: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader()

                reader.onloadend = async () => {
                    try {
                        const buffer = reader.result
                        await axios.put(signedUrl, buffer, {
                            headers: {},
                        })
                        resolve() // Resolve the promise here
                    } catch (error) {
                        reject(error) // Reject the promise in case of an error
                    }
                }

                reader.onerror = (e) => {
                    reject(e) // Reject the promise in case of an error
                }

                reader.readAsArrayBuffer(file)
            } catch (e) {
                reject(e) // Reject the promise in case of an error
            }
        })
    }

    const respond = async (
        respondQuestion: Response,
        // token: string
    ): Promise<void> => {
        try {
            if (respondQuestion.formData) {
                setShowSpinner(true)
                const preSignedUrlResponse = await NodeioApi.generatePreSignedUrl({
                    responding_to_question_id: respondQuestion.questionId,
                    request_id: requestId,
                    first_name: firstName ?? '',
                    last_name: lastName ?? '',
                    email: email ?? '',
                })
                const url = preSignedUrlResponse.data.url
                const file: File = respondQuestion.formData?.get('file') as File
                await uploadFile(file, url)

                setResponses([])
            } else {
                await NodeioApi.create({
                    responding_to_question_id: respondQuestion.questionId,
                    request_id: requestId,
                    title: respondQuestion.title ?? '',
                    first_name: firstName?.length ? firstName : undefined,
                    last_name: lastName?.length ? lastName : undefined,
                    email: email?.length ? email : undefined,
                    client: Client.WEB_APP,
                })
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e)
            Sentry.captureException('Failed to save responses.')
            Sentry.captureException(e)
            setErrorSubmitting(true)
        } finally {
            setShowSpinner(false)
        }
    }

    const handleSubmit = async (): Promise<void> => {
        setIsSubmitted(true)
        setErrorSubmitting(false)
        setShouldBlockNavigation(false)
        let hasNotResponded = !questions
            .filter(
                (question) =>
                    question.question_settings.require_question_response === Mandatory.REQUIRED,
            )
            .every(
                (question) =>
                    responses.find((response) => response.questionId === question.id) ||
                    responseNotes.find(
                        (responseNote) =>
                            responseNote.questionId === question.id &&
                            !_.isEmpty(responseNote.title),
                    ),
            )
        setRequiredToAllResponds(hasNotResponded)

        if (hasNotResponded) return

        try {
            setShowSpinner(true)

            await Promise.all(
                responseNotes
                    .map((responseNote) => {
                        let response = responses.find(
                            (response) => response.questionId === responseNote.questionId,
                        )
                        if (response) return response
                        return {
                            questionId: responseNote.questionId,
                            title:
                                questions.find(
                                    (question) => question.id === responseNote.questionId,
                                )?.question_settings.type === QuestionTypeSettings.MULTI_SELECT
                                    ? JSON.stringify(responseNote.title)
                                    : responseNote.title,
                        }
                    })
                    .filter((response) => response.title !== undefined)
                    .map((response) => respond(response)),
            )
            navigate(Page.SUCCESSFULLY_SAVED_RESPONSES)
        } catch (e: any) {
            if (!window.navigator.onLine && !e.response && e.code === 'ERR_NETWORK') {
                // eslint-disable-next-line no-console
                console.log(e)
                Sentry.captureException('No internet connection.  Try submitting again.')
                Sentry.captureException(e)
                setErrorSubmitting(true)
            }
        } finally {
            setIsSubmitted(false)
            setShowSpinner(false)
        }
    }

    const requireFirstName: Mandatory =
        selectedQuestion?.question_settings?.require_first_name ?? Mandatory.HIDDEN
    const requireEmail: Mandatory =
        selectedQuestion?.question_settings?.require_email ?? Mandatory.HIDDEN

    return (
        <div className="w-full h-screen">
            <div className="w-full h-[95%] relative flex flex-col justify-center items-center web-responder">
                <SelectLanguage />
                <div
                    className="relative w-full h-[330px] xs:h-[290px] sm:h-[250px] flex justify-center"
                    style={{
                        background:
                            selectedQuestion?.author?.brand?.background_color ||
                            defaultBrandBgColor,
                    }}
                >
                    {selectedQuestion?.author?.brand?.background_image_url && (
                        <img
                            src={selectedQuestion?.author?.brand?.background_image_url}
                            className="w-full h-full absolute object-cover hidden sm:block"
                        />
                    )}
                    {selectedQuestion?.author?.brand?.background_mobile_image_url && (
                        <img
                            src={selectedQuestion?.author?.brand?.background_mobile_image_url}
                            className="w-full h-full absolute object-cover block sm:hidden"
                        />
                    )}
                </div>
                <div className="flex flex-1 bg-white"></div>
                <div
                    className={`${
                        !questions[0]?.author?.account_claimed ? 'top-[35px]' : 'top-[140px]'
                    } absolute flex flex-col items-center w-screen md:w-fit bg-transparent px-[25px] md:px-[0px] pb-10 sm:pb-0`}
                >
                    {!loading && !questions[0]?.author?.account_claimed && (
                        <div className="mt-2 mb-8 sm:mb-[60px] px-3 w-full lg:px-10 lg:w-[1024px] xl:w-[1280px]">
                            <p className="text-[#001F40] text-base font-bold mb-0.5 mt-1">
                                {t('question_responder_page.get_real_time_insights')}
                            </p>
                            <p className="text-[#001F40] text-sm">
                                {t('question_responder_page.share_link_edit_create')}
                            </p>
                            <div
                                className="text-[#BFDEFF] text-base font-bold flex bg-[#007AFF] rounded-2xl w-fit pl-4 pr-6 pt-[5px] pb-[6px] mt-2.5 break-all cursor-pointer"
                                onClick={() =>
                                    navigate(Page.SIGN_UP, {
                                        state: { user: questions[0]?.author },
                                    })
                                }
                            >
                                <img src="/asset/magic-icon.svg" className="w-[22px] h-[22px]" />
                                <p>
                                    <span className="ml-2">
                                        {t('question_responder_page.try_for_free')}
                                    </span>
                                </p>
                            </div>
                        </div>
                    )}
                    <div>{showSpinner && <Spinner />}</div>
                    <QuestionResponderQuestionCard
                        questions={questions}
                        questionIndex={questionIndex}
                        onQuestionIndexChange={setQuestionIndex}
                        responseForSelectedQuestion={responseForSelectedQuestion}
                        responseNoteForSelectedQuestion={responseNoteForSelectedQuestion}
                        setResponseNotes={setResponseNotes}
                        selectedLanguage={i18n.language as Language}
                        isRecording={isRecording}
                    />
                    <div className="w-full md:w-[720px] flex flex-col justify-center mb-20">
                        <div>
                            {/* show recording button only if no response recorder for selected question */}
                            {!responseForSelectedQuestion &&
                                selectedQuestion?.question_settings.type ===
                                    QuestionTypeSettings.VOICE_ONLY && (
                                    <VoiceOnlyResponder
                                        setResponses={setResponses}
                                        setRequiredToAllResponds={setRequiredToAllResponds}
                                        setQuestionIndex={setQuestionIndex}
                                        setIsRecordingSupported={setIsRecordingSupported}
                                        setResponseNotes={setResponseNotes}
                                        selectedQuestion={selectedQuestion}
                                        questionIndex={questionIndex}
                                        responseNoteForSelectedQuestion={
                                            responseNoteForSelectedQuestion
                                        }
                                        questions={questions}
                                        isQuestionResponder
                                        showNotes={
                                            !!selectedQuestion?.question_settings?.show_notes
                                        }
                                        setIsRecording={setIsRecording}
                                    />
                                )}
                            {!responseForSelectedQuestion &&
                                selectedQuestion?.question_settings.type ===
                                    QuestionTypeSettings.VOICE_OR_TEXT && (
                                    <VoiceTextResponder
                                        setResponses={setResponses}
                                        setRequiredToAllResponds={setRequiredToAllResponds}
                                        setQuestionIndex={setQuestionIndex}
                                        setIsRecordingSupported={setIsRecordingSupported}
                                        setResponseNotes={setResponseNotes}
                                        selectedQuestion={selectedQuestion}
                                        questionIndex={questionIndex}
                                        responseNoteForSelectedQuestion={
                                            responseNoteForSelectedQuestion
                                        }
                                        questions={questions}
                                        showNotes={
                                            !!selectedQuestion?.question_settings?.show_notes
                                        }
                                        setIsRecording={setIsRecording}
                                    />
                                )}
                            {!responseForSelectedQuestion &&
                                selectedQuestion?.question_settings.type ===
                                    QuestionTypeSettings.SINGLE_SELECT && (
                                    <SingleSelectResponder
                                        setResponses={setResponses}
                                        setRequiredToAllResponds={setRequiredToAllResponds}
                                        setQuestionIndex={setQuestionIndex}
                                        setIsRecordingSupported={setIsRecordingSupported}
                                        setResponseNotes={setResponseNotes}
                                        selectedQuestion={selectedQuestion}
                                        questionIndex={questionIndex}
                                        responseNoteForSelectedQuestion={
                                            responseNoteForSelectedQuestion
                                        }
                                        disabled={!accepted}
                                        questions={questions}
                                        isQuestionResponder={true}
                                    />
                                )}
                            {!responseForSelectedQuestion &&
                                selectedQuestion?.question_settings.type ===
                                    QuestionTypeSettings.MULTI_SELECT && (
                                    <MultipleSelectResponder
                                        setResponses={setResponses}
                                        setRequiredToAllResponds={setRequiredToAllResponds}
                                        setQuestionIndex={setQuestionIndex}
                                        setIsRecordingSupported={setIsRecordingSupported}
                                        setResponseNotes={setResponseNotes}
                                        selectedQuestion={selectedQuestion}
                                        questionIndex={questionIndex}
                                        responseNoteForSelectedQuestion={
                                            responseNoteForSelectedQuestion
                                        }
                                        disabled={!accepted}
                                        questions={questions}
                                        isQuestionResponder={true}
                                    />
                                )}
                            {/* show response preview if response is recorder for selected question */}
                            {responseForSelectedQuestion && selectedQuestion && (
                                <div className="mt-10">
                                    <ResponsePreview
                                        question={selectedQuestion}
                                        responseForSelectedQuestion={responseForSelectedQuestion}
                                        onReRecord={() => {
                                            setResponses(
                                                responses.filter(
                                                    (response) =>
                                                        response.questionId !== selectedQuestion.id,
                                                ),
                                            )
                                            setResponseNotes(
                                                responseNotes.map((responseNote) => {
                                                    if (
                                                        responseNote.questionId ===
                                                        selectedQuestion.id
                                                    )
                                                        return {
                                                            questionId: selectedQuestion.id,
                                                            note: '',
                                                            open: false,
                                                        }
                                                    return responseNote
                                                }),
                                            )
                                        }}
                                    />
                                </div>
                            )}

                            {!isQuestionResponderLanded && !accepted && openFooter && (
                                <p className="text-sm text-[#007AFF] font-bold mt-6">
                                    {t('recorder_button.please_accept_the_terms_first')}
                                </p>
                            )}

                            {/*/!*  show AddMoreInformation if all questions are answered *!/*/}
                            {questionIndex === questions.length - 1 && (
                                <AddMoreInformation
                                    requiredToAllResponds={requiredToAllResponds}
                                    question={questions[0]}
                                    handleSubmit={handleSubmit}
                                    isSubmitted={isSubmitted}
                                    requireFirstName={requireFirstName}
                                    requireEmail={requireEmail}
                                    firstName={firstName}
                                    setFirstName={setFirstName}
                                    lastName={lastName}
                                    setLastName={setLastName}
                                    email={email}
                                    setEmail={setEmail}
                                    nonRespondedRequiredQuestions={nonRespondedRequiredQuestions}
                                    isLastQuestionRequired={
                                        /* eslint-disable-next-line */
                                        questions[questions.length - 1].question_settings
                                            .require_question_response === Mandatory.REQUIRED ??
                                        true
                                    }
                                    isLastQuestionResponded={
                                        !!responses.find(
                                            (response) =>
                                                response.questionId ===
                                                questions[questions.length - 1].id,
                                        ) ||
                                        !!responseNotes.find(
                                            (responseNote) =>
                                                responseNote.questionId ===
                                                    questions[questions.length - 1].id &&
                                                !_.isEmpty(responseNote.title),
                                        )
                                    }
                                />
                            )}
                        </div>
                        {errorSubmitting && (
                            <p className="mt-4 mb-4 text-red-400">
                                🚨 {t('error_messages.submit_error')}
                            </p>
                        )}
                        {!isRecordingSupported && (
                            <p className="mt-4 text-red-400">
                                {t('question_responder_page.note_recording_not_supported')}
                            </p>
                        )}
                    </div>
                </div>
                <div
                    className={`fixed bottom-0 w-full left-0 bg-[#0D0D0D] rounded-t-lg px-[40px] lg:px-[100px] py-[30px] md:pb-[40px] lg:pb-[50px] flex-col md:flex-row items-center justify-between gap-5 transition-all ${
                        openFooter && !accepted ? 'flex' : 'hidden'
                    }`}
                >
                    <div>
                        <h2 className="text-xl leading-9 font-bold text-[#BFDEFF]">
                            {t('recorder_button.before_user_can_hear_your_voice').replace(
                                '{first_name}',
                                selectedQuestion?.author?.first_name || '',
                            )}
                        </h2>
                        <p className="text-[15px] text-[#BFDEFF]">
                            {!selectedQuestion?.question_settings.custom_consent
                                ? t('recorder_button.in_order_to_respond_please_accept_our')
                                : t('recorder_button.custom_consent_text')}{' '}
                            {selectedQuestion?.question_settings.custom_consent ||
                            selectedQuestion?.author.id ===
                                '24382cfe-5435-4e44-b5cf-86e547ed99ea' ? (
                                <span>
                                    <a
                                        target="_blank"
                                        className="hover:underline text-[#007AFF]"
                                        href="https://www.chicagobears.com/about/privacy-policy"
                                    >
                                        {t('recorder_button.privacy_and_policy_custom_consent')}{' '}
                                    </a>
                                </span>
                            ) : (
                                <>
                                    <a
                                        href="/terms"
                                        target="_blank"
                                        className="hover:underline text-[#007AFF]"
                                    >
                                        {t('recorder_button.terms_of_use')}
                                    </a>{' '}
                                    {t('common.and')}{' '}
                                    <a
                                        href="/privacy"
                                        target="_blank"
                                        className="hover:underline text-[#007AFF]"
                                    >
                                        {t('recorder_button.privacy_and_policy')}
                                    </a>
                                    {selectedQuestion?.author.id === FINAL_THIRD_FOUNDATION_ID && (
                                        <>
                                            <span>
                                                .{' '}
                                                {t(
                                                    'recorder_button.accepting_serves_as_your_consent_to_the',
                                                )}{' '}
                                            </span>
                                            <a
                                                href="https://docs.google.com/document/d/1p8TQ7qZS5p9wUSKJCGteginWb_JmMuKemHO14N4d48o/edit"
                                                target="_blank"
                                                className="hover:underline text-[#007AFF]"
                                            >
                                                {t('recorder_button.media_release_form')}
                                            </a>
                                            <span>
                                                {' '}
                                                {t('recorder_button.of')} Final Third Foundation.
                                            </span>
                                        </>
                                    )}
                                </>
                            )}
                        </p>
                    </div>
                    <div className="flex gap-4">
                        <button
                            className="text-sm bg-[#007AFF] text-[#FFFFFF] font-bold px-12 py-2 rounded-lg"
                            onClick={() => {
                                setQuestionResponderLanded(false)
                                setOpenFooter(false)
                                setAccepted(true)
                            }}
                        >
                            {t('common.accept')}
                        </button>
                        <button
                            className="text-sm bg-[#FFFFFF] text-[#001F40DE] font-bold px-12 py-2 rounded-lg"
                            onClick={() => {
                                setQuestionResponderLanded(false)
                                setAccepted(false)
                                setOpenFooter(false)
                            }}
                        >
                            {t('common.decline')}
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex justify-end mt-5 mb-10 mr-10">
                <a
                    href="/privacy"
                    target="_blank"
                    className="hover:underline text-[#000000] text-sm font-lato leading-[17px]"
                >
                    Tone AI {t('recorder_button.privacy_and_policy_custom_consent')}
                </a>
            </div>
        </div>
    )
}
export default QuestionResponder
